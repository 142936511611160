import React from "react";
import Authheader from "../layout/header/autheader";

import appMockup from "./assets/app-mockup@2x.png";
import heartIcon from "./assets/heart-icon.svg";
import appStoreButton from "./assets/app-store@2x.png";
import googlePlayButton from "./assets/google-play@2x.png";

export default function Confirmation({ location }) {
  return (
    <>
      <Authheader />
      <div className="confirmation">
        <div className="container d-md-flex d-block">
          <div className="confirmation-main">
            <h2 className="title">Welcome!</h2>
            <p className="subtitle">We’re excited to have you. </p>
            <div className="get-app-title">
              <img src={heartIcon} alt="heart icon" /> One More Step
            </div>
            <div className="get-app-text">
              <p>
                {location.search === "?expert"
                  ? `To take full advantage of the platform, answer questions and get matchd with patients, please download the free mobile application.`
                  : `To take full advantage of the platform and get matched with your fertility partner, please download the free mobile application.`}
              </p>
            </div>
            <div className="get-app-buttons">
              <a
                href="https://apps.apple.com/us/app/fertilityanswers/id1418650176"
                target="_blank"
              >
                <img
                  src={appStoreButton}
                  height="38"
                  alt="Download on the app store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.fertilityanswers"
                target="_blank"
              >
                <img
                  src={googlePlayButton}
                  height="38"
                  alt="Get it on google play"
                />
              </a>
            </div>
          </div>
          <div className="confirmation-side">
            <img src={appMockup} alt="fertility answers mobile app" />
          </div>
        </div>
        <div className="color-block"></div>
      </div>
    </>
  );
}
